import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

function SignupForm() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/register");
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ background: "#2121211A" }}
    >
      <div className="h-[650px] bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
        {/* Header Section */}
        <div class="flex items-center">
          <img
            src="/assets/images/owlLogo.png"
            alt="Scoolish Logo"
            class="w-12 h-12"
          />
          <div class="ml-4">
            <h1 class="text-2xl font-semibold">Welcome to Scoolish</h1>
            <p className="text-sm text-gray-600">
              {" "}
              Create an account
              <br />
              Already have an account?{" "}
              <a href="/login" className="text-blue-500 font-medium">
                Login
              </a>
            </p>
          </div>
        </div>
        {/* Form Section */}
        <form className="mt-6">
          <div className="flex justify-center mb-4">
            <input
              type="email"
              placeholder="enter email id"
              className="w-full p-3 border rounded-3xl focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-md flex justify-center mt-4">
            <Button
              type="submit"
              onClick={handleClick}
              className="bg-black text-white px-8 py-2 pl-16 pr-16 hover:bg-gray-500 transition"
            >
              Next
            </Button>
          </div>
        </form>
        {/* Divider */}
        <div className="flex items-center justify-between my-6">
          <hr className="w-full border-gray-300" />
          <span className="px-2 font-bold text-black-500">Or</span>
          <hr className="w-full border-gray-300" />
        </div>
        {/* Social Login Buttons */}
        <div className="space-y-4">
          <Button className="flex items-center justify-center w-full border border-gray-300 hover:bg-gray-50 transition py-2">
            <img
              src="/assets/images/gmailLogo.png"
              alt="Google"
              className="w-5 h-5 mr-2"
            />
            Log in with Gmail
          </Button>
          <Button className="flex items-center justify-center w-full border border-gray-300 hover:bg-gray-50 py-2">
            <img
              src="/assets/images/appleLogo.png"
              alt="Apple"
              className="w-5 h-5 mr-2"
            />
            Log in with Apple
          </Button>
          <Button className="flex items-center justify-center w-full border border-gray-300 py-2 hover:bg-gray-50 transition">
            Use SSO
          </Button>
        </div>
        {/* Terms and Privacy */}
        <div className="text-center mt-6 text-xs text-black-600">
          By clicking “Next”, “Sign up with Google” or “Sign up with Apple” you
          agree to our{" "}
          <a href="/terms" className="text-blue-500">
            Terms of Use
          </a>{" "}
          and acknowledge that you have read and understand our{" "}
          <a href="/privacy" className="text-blue-500">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
