import React from 'react';

function RecentBlog() {
    return (
        <section className="gap-2 max-w-6xl mx-auto bg-white text-black mb-32">
        <div className="container mt-16 mx-auto px-4">
          <h2 className="text-5xl font-semibold mt-8 mb-2 text-center">STORY TO COMICS CONVERTER: INSTANTLY VISUALIZE YOUR IDEAS</h2>
          <p className="text-md font text-center text-gray-600 ">Experience the power of SCOOLISH Text to Image module, which enables you to transform textual </p>
          <p className="text-md font text-center text-gray-600 mb-8 ">prompts into stunning visuals. Check this out in the Story to comics Module of AI Tools.</p>
          <div className="grid grid-cols-4 gap-4 ">
            <div className="row-span-2 col-span-1">
              <img src={"/assets/images/cosmicDiv1.jpeg"} alt="Image 1" className="w-full h-full object-cover rounded-xl" />
            </div>
            <div className="row-span-1 col-span-2">
              <img src={"/assets/images/cosmicDiv2.jpeg"} alt="Image 2" className="w-full h-full object-cover rounded-xl" />
            </div>
            <div className="row-span-2 col-span-1">
              <img src={"/assets/images/cosmicDiv3.jpeg"} alt="Image 3" className="w-full h-full object-cover rounded-xl" />
            </div>
            <div className="row-span-1 col-span-1">
              <img src={"/assets/images/cosmicDiv4.jpeg"} alt="Image 4" className="w-full h-full object-cover rounded-xl" />
            </div>
            <div className="row-span-1 col-span-1">
              <img src={"/assets/images/cosmicDiv5.jpeg"} alt="Image 5" className="w-full h-full object-cover rounded-xl" />
            </div>
          </div>
        </div>
      </section>
    );
}

export default RecentBlog;