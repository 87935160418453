import React from "react";

const LazyVideo = ({ src, ...props }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const videoRef = React.useRef();
  
    React.useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.1 }
      );
  
      if (videoRef.current) observer.observe(videoRef.current);
  
      return () => observer.disconnect();
    }, []);
  
    return (
      <video
        ref={videoRef}
        src={isVisible ? src : ""}
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
        {...props}
      />
    );
  };

export default LazyVideo;
  