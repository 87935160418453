import React from "react";

function FooterColumn({ title, items }) {
  return (
    <>
      <div className="flex-1">
        <ul className="text-gray-400">
          <h4 className="text-lg font-semibold text-white">{title}</h4>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FooterColumn;
