import React from 'react';
import Button from './Button';

function Plans() {
  const plans = [
    {
      title: 'Growth',
      price: '$59.00/',
      summary: 'Best suited for brands under 50k visitors/month',
      containerClass: 'bg-white shadow-md rounded-lg p-6 text-center h-415px',
      features: [
        'AI-Powered Homework Helper',
        'Interactive Quiz Creator',
        'Visual Study Guide Maker',
        'Limited Analytic',
        'Limited Reporting'
      ],
      buttonClass: 'bg-black text-white' // Default button class
    },
    {
      title: 'Scale',
      price: '$129.00/',
      summary: 'Best suited for enterprises under 150k visitors/month',
      containerClass: 'bg-black border border-white text-white shadow-md rounded-lg p-6 text-center h-415px',
      features: [
        'Everything in Basic, plus:',
        'AI-Powered Text Analysis',
        'Real-Time Feedback Systems',
        'Comprehensive Analytics',
        'Comprehensive Reporting'
      ],
      buttonClass: 'bg-white text-black' // White background, black text
    },
    {
      title: 'Premium',
      price: '$299.00/',
      summary: 'Best suited for brands under 400k visitors/month',
      containerClass: 'bg-white shadow-md rounded-lg p-6 text-center h-415px',
      features: [
        'Everything in Advanced, plus:',
        'Customized Learning Paths',
        'Historical Timeline Builder',
        'Digital Debate Platform',
        'Story to Comics Converter',
      ],
      buttonClass: 'bg-black text-white' // Default button class
    },
  ];

  return (
    <div className="mx-auto px-64 py-16 bg-black">
      <h2 className="text-5xl italic text-white font-semibold mb-4 text-center">Plans We Offer</h2>
      <p className="text-md font text-center text-gray-600 mb-12">Start with 7-day free trial. No credit card needed. Cancel at anytime.</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            style={{ height: "530px" }}
            className={plan.containerClass}
          >
            <h3 className="text-2xl text-left font-bold mb-4">{plan.title}</h3>
            <p className="text-md text-left mb-4 mb-8">{plan.summary}</p>
            <p className="text-3xl text-left font-bold mb-4">
              {plan.price}
              <span className="text-sm">month</span>
            </p>
            <div className="flex items-center justify-between my-6">
              <hr className="w-full border-gray-300 mt-4 mb-4" />
            </div>
            <ul className="list-none p-0">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-left mb-2">
                  <svg
                    className="w-6 h-6 inline-block mr-2 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <Button className={`w-full ${plan.buttonClass} font-bold py-2 px-4 mt-4`}>
              Start Trial
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plans;