import React, { useState } from 'react';
import Button from '../../components/Button';

const registerationDetails = [
  {
    label: 'First Name',
    type: 'text',
    placeholder: 'First Name'
  },
  {
    label: 'Last Name',
    type: 'text',
    placeholder: 'Last Name'
  },
  {
    label: 'Organization',
    type: 'text',
    placeholder: 'Organization (optional)'
  },
  {
    label: 'Username',
    type: 'text',
    placeholder: 'Enter your username',
  },
  {
    label: 'Password',
    type: 'password',
    placeholder: 'Enter your password',
  },
  {
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Confirm your password',
  },
];

const Registration = () => {
  const [inputFields, setInputFields] = useState(registerationDetails.slice(3,6))

  function onNextHandler() {
    setInputFields(registerationDetails.slice(0,3))
  }

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ background: "#2121211A" }}
    >
      <div className="h-[650px] bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
        {/* Header Section */}
        <div className="flex items-center">
          <img
            src={"/assets/images/owlLogo.png  "}
            alt="Scoolish Logo"
            className="w-12 h-12"
          />
          <div className="ml-4">
            <h1 className="text-2xl font-semibold">Welcome to Scoolish</h1>
            <p className="text-sm text-gray-600">
              Create an account
              <br />
              Already have an account?{" "}
              <a href="/login" className="text-blue-500 font-medium">
                Login
              </a>
            </p>
          </div>
        </div>

        {/* Form Section */}
        <form className="mt-6">
          {inputFields.map((field, index) => (
            <div key={index} className="mb-4">
              <input
                type={field.type}
                placeholder={field.placeholder}
                className="w-full p-3 border rounded-3xl focus:outline-none focus:ring focus:ring-blue-300"
              />
            </div>
          ))}
          <div className="w-md flex justify-center mt-4">
            <Button
              type="button"
              onClick={onNextHandler}
              className="bg-black text-white px-8 py-2 pl-16 pr-16 hover:bg-gray-500 transition"
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registration;