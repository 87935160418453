import React from "react";
import FooterBottom from "./FooterBottom";
import FooterColumn from "./FooterColumn";

function Footer() {
  return (
    <footer className="bg-black text-white">
      {/* Footer Section */}
      <div className="py-6 px-6">
        <div className="flex h-48">
          {/* Column 1: Branding */}
          <div className="w-[35%]">
            <h3 className="text-2xl ">
              Scoolish <span className="text-purple-700">AI</span>
            </h3>
            <p className="mt-4 text-gray-400">
            Unlock your potential with Scoolish—your all-in-one platform for smarter learning and growth!
            </p>
          </div>
          {/* Column 2: Navigation */}
          <FooterColumn
            title="NAVIGATION"
            items={[
              "Home",
              "About Us",
              "What We Do",
              "To The Power of 10",
              "Donate",
            ]}
          />
          {/* Column 3: What We Do */}
          <FooterColumn
            title="WHAT WE DO"
            items={[
              "Encouraging Testing",
              "Strengthening Advocacy",
              "Sharing Information",
              "Building Leadership",
              "Engaging With Global Fund",
              "Shining a Light",
            ]}
          />
          {/* Column 4: Legal */}
          <FooterColumn
            title="LEGAL"
            items={["General Info", "Privacy Policy", "Terms of Service"]}
          />
          {/* Column 5: Talk To Us */}
          <FooterColumn
            title="TALK TO US"
            items={[
              "support@ercom.com",
              "+66 2399 1145",
              "Contact Us",
              "Facebook",
              "LinkedIn",
              "Twitter",
            ]}
          />
        </div>
      </div>
      {/* Footer Bottom Section */}
      <FooterBottom />
    </footer>
  );
}

export default Footer;
