import React from "react";

const FooterBottom = () => {
  return (
    <>
      <div className="py-2 border-t border-gray-700 text-center">
        <p className="text-gray-500 text-sm">
          © 2024 Scoolish Content. All Rights Reserved.
        </p>
        <div className="flex justify-center space-x-4">
          <a href="#" className="text-gray-500 hover:text-white">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-white">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-white">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-white">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-white">
            <i className="fa fa-youtube-play"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default FooterBottom;
