import React from "react";
import Button from "./Button";

const PremiumPlan = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-black text-white pt-16 px-32 space-y-8">
      <h1 className="text-center text-xl md:text-3xl font-bold">
        Take Our Premium Plan 20% Off hurry up!
      </h1>

      <div className="flex flex-row items-center space-x-4">
        <img
          src="/assets/images/premium1.png"
          alt="Illustration 1"
          className="w-32 h-32 md:w-40 md:h-40 rounded"
        />
        <div>
          <p className="text-center text-sm md:text-lg">
            Also than having free Webflow cloneables, we create cutting-edge
            premium Webflow templates too.
          </p>
          <Button className="bg-white text-black px-4 py-2 mt-8 text-sm md:text-base font-medium hover:bg-gray-200">
            Try Now Premium
          </Button>
        </div>

        <img
          src="/assets/images/premium2.png"
          alt="Illustration 2"
          className="w-32 h-32 md:w-40 md:h-40 rounded"
        />
      </div>
    </div>
  );
};

export default PremiumPlan;
