import React from "react";

function HomeCard({logo, title, summary}) {
  return (
    <div
      className="bg-black text-white p-6 px-16 rounded-xl shadow-md flex flex-col items-left h-[340px]"
    >
      {/* Icon */}
      <div className="w-24 h-24 bg-white rounded-full flex items-center justify-center mt-4">
        <img
          src={logo}
          alt="Summarization Icon"
          className="w-16 h-16"
        />
      </div>
      {/* Title */}
      <h3 className="text-2xl font-semibold text-left mt-8">{title}</h3>
      {/* Description */}
      <p className="text-sm text-gray-300 text-left mt-2 mb-16">
        {summary}
      </p>
      {/* CTA Button */}
      <a
        href="#"
        className="text-white-400 text-left hover:underline text-sm font-medium"
      >
        Click Here
      </a>
    </div>
  );
}

export default HomeCard;
