import React from "react";
import SidebarList from "./SidebarList";

function Sidebar() {
  const sidebarData = [
    {
      title: "MAIN",
      items: [
        { name: "Home", class: "fa fa-home",  },
        { name: "Template", class: "fa fa-file" },
        { name: "Schedules", class: "fa fa-calendar" },
      ],
    },
    {
      title: "MAIN",
      items: [
        { name: "Home", class: "fa fa-home " },
        { name: "Template", class: "fa fa-file" },
        { name: "Schedules", class: "fa fa-calendar" },
      ],
    },
    {
      title: "LIBRARY",
      items: [
        { name: "My Resources", class: "fa fa-folder " },
        { name: "Shared Resources", class: "fa fa-users " },
        { name: "Favourite Resources", class: "fa fa-star " },
        { name: "Recent Tools", class: "fa fa-history " },
      ],
    },
    {
      title: "TOOLS",
      items: [
        { name: "AI Homework Helper", class: "fa fa-book" },
        { name: "Comics Creator", class: "fa fa-star" },
        { name: "AI Language Translator", class: "fa fa-language" },
      ],
    },
    {
      title: "AI Labs",
      items: [
        { name: "Research", class: "fa fa-flask " },
        { name: "Learning Path", class: "fa fa-road " },
      ],
    },
    {
      title: "Class",
      items: [
        { name: "Analytics", class: "fa fa-graph" },
        { name: "Similar Research", class: "fa fa-search " },
        { name: "Trending Tools", class: "fa fa-fire " },
        { name: "Competitions", class: "fa fa-trophy " },
      ],
    },
  ];

  return (
    <nav className="space-y-2 bg-secondary rounded-xl ml-4 pl-2 pr-2 pt-0.5 pb-2 overflow-y-auto  h-[calc(100vh-9rem)] scrollbar">
      {sidebarData.map((item, index) => (
        <>
          <ul className="">
            <li className="font-small text-sm font-inter text-t-secondary px-6 py-1">
              {item.title}
            </li>
            <SidebarList items={item.items} />
          </ul>
        </>
      ))}
    </nav>
  );
}

export default Sidebar;
