import React from "react";
import Button from "./Button";

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-black flex justify-center items-center pb-6">
      <div className="flex flex-col lg:flex-row w-full max-w-6xl text-white">
        {/* Left Section */}
        <div className="lg:w-3/5 p-8 bg-black">
          <h2 className="text-3xl text-left font-bold mt-16 mb-16">Contact Us</h2>
          <div className="space-y-4">
            {/* Phone */}
            <div className="flex items-center space-x-4">
              <a href="#" className="text-gray-500 hover:text-white">
                <i className="fa fa-phone"></i>
              </a>
              <span>+91-9871188346</span>
            </div>
            {/* Email */}
            <div className="flex items-center space-x-4">
              <a href="#" className="text-gray-500 hover:text-white">
                <i className="fa fa-envelope"></i>
              </a>
              <span>enquire@scoolish.com</span>
            </div>
            {/* Website */}
            <div className="flex items-center space-x-4">
              <a href="#" className="text-gray-500 hover:text-white">
                <i className="fa fa-globe"></i>
              </a>
              <span>www.scoolish.com</span>
            </div>
            {/* Address */}
            <div className="flex items-center space-x-4">
              <a href="#" className="text-gray-500 hover:text-white">
                <i className="fa fa-map"></i>
              </a>
              <span>E310, East of Kailash, New Delhi, 110065</span>
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div className="lg:w-2/5 p-8 rounded-2xl bg-white text-black">
          <form className="space-y-6 ">
            {/* Name */}
            <div>
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            {/* Phone */}
            <div>
              <input
                type="text"
                placeholder="Phone Number"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            {/* Email */}
            <div>
              <input
                type="email"
                placeholder="Email Address"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            {/* Message */}
            <div>
              <textarea
                placeholder="Message"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-black"
                rows="10"
              ></textarea>
            </div>
            {/* Submit Button */}
            <div className="w-md flex justify-left mt-4">
            <Button
              type="submit"
              className="bg-black text-white px-16 py-2 pl-15 pr-15 hover:bg-gray-500 transition"
            >
              Submit
            </Button>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
