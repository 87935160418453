import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import axios from "axios"; // Axios for API calls

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post("https://cors-anywhere.herokuapp.com/http://172.178.120.199/api/auth/login", {
        username,
        password,
      });

      console.log(response);
  
      if (response.data.message === "Login successful" && response.data.status === "success") {
        // Save authentication status (e.g., token or flag)
        localStorage.setItem("isAuthenticated", "true"); // Or save a token from the response
  
        // Navigate to Home component
        navigate("/home");
      } else {
        alert("Unauthorized: " + (response.data.message || "Invalid credentials"));
      }
    } catch (error) {
      console.error("Login Error: ", error);
      alert("An error occurred while logging in. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="min-h-screen">
      {/* Announcement Banner */}
      <div className="w-full bg-red-500 text-white text-center">
        This website is under development, scoolish will be live soon.
      </div>
      <div
        className="flex flex-col pt-14 items-center pb-10 justify-center bg-cover bg-center"
        style={{ background: "#2121211A" }}
      >
        {/* Login Form Container */}
        <div className="h-full bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
          {/* Header Section */}
          <div className="flex items-center">
            <img
              src="/assets/images/owlLogo.png"
              alt="Scoolish Logo"
              className="w-12 h-12"
            />
            <div className="ml-4">
              <h1 className="text-2xl font-semibold">Welcome to Scoolish</h1>
              <p className="text-sm text-gray-700">
                Login
                <br />
                Don’t have an account?{" "}
                <a href="/signup" className="text-blue-500 font-medium">
                  Sign up for free
                </a>
                .
              </p>
            </div>
          </div>
          {/* Form Section */}
          <form className="mt-6" onSubmit={handleLogin}>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter username or email"
                className="w-full p-3 border rounded-3xl focus:outline-none focus:ring focus:ring-blue-300"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full p-3 border rounded-3xl focus:outline-none focus:ring focus:ring-blue-300"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="w-md flex justify-center mt-4">
              <Button
                type="submit"
                className="bg-black text-white px-16 py-2 pl-15 pr-15 hover:bg-gray-500 transition"
                disabled={loading}
              >
                {loading ? "Logging in..." : "Login"}
              </Button>
            </div>
          </form>
          {/* Divider */}
          <div className="flex items-center justify-between my-6">
            <hr className="w-full border-gray-300" />
            <span className="px-2 font-bold text-black-500">Or</span>
            <hr className="w-full border-gray-300" />
          </div>
          {/* Social Login Buttons */}
          <div className="space-y-4">
            <Button className="flex items-center justify-center w-full border border-gray-300 py-2 hover:bg-gray-50 transition">
              <img
                src="/assets/images/gmailLogo.png"
                alt="Google"
                className="w-5 h-5 mr-2"
              />
              Log in with Gmail
            </Button>
            <Button className="flex items-center justify-center w-full border border-gray-300 py-2 hover:bg-gray-50 transition">
              <img
                src="/assets/images/appleLogo.png"
                alt="Apple"
                className="w-5 h-5 mr-2"
              />
              Log in with Apple
            </Button>
            <Button className="flex items-center justify-center w-full border border-gray-300 py-2 hover:bg-gray-50 transition">
              Use SSO
            </Button>
          </div>
          {/* Forgot Password */}
          <p className="text-sm text-center font-bold text-black-800 mt-2">
            <br />
            Forgot Password?{" "}
            <a href="/signup" className="text-blue-500 font-medium">
              Click Here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
