import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const Navbar = () => {
  const navigate = useNavigate();
  const navLinks = [
    { label: 'Features' },
    { label: 'Tools' },
    { label: 'Research' },
    { label: 'Company' },
  ];

  return (
    <nav className="fixed top-0 left-0 w-full bg-white text-black z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        {/* Logo and branding */}
        <div className="flex items-center pl-24">
          <img
            src={"/assets/images/owlLogo.png"}
            alt="Scoolish Logo"
            className="h-10 w-10"
          />
          <span className="text-xl font-bold ml-2">Scoolish</span>
        </div>

        {/* Navigation links */}
        <div className="hidden md:flex space-x-4">
          {navLinks.map((link, index) => (
            <Button
              key={index}
              className="px-1 py-2 hover:text-blue-800 transition"
            >
              {link.label}
            </Button>
          ))}
        </div>

        {/* Call-to-action button */}
        <div className="flex justify-center pr-24">
          <Button onClick={() => navigate("/login")} className="px-1 py-2 pl-6 pr-6 bg-black text-white hover:bg-gray-500 transition">
            Get Started
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
