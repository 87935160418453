import React from "react";
import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";

const Dashboard = () => {
  return (
    <div className="flex bg-black text-white h-screen">
      <div className="fixed w-80 bg-black p-6 h-screen">
        <h1 className="text-2xl font-bold mb-6 flex items-center space-x-4">
          <div className="bg-secondary py-2 pl-4 pr-12 ml-4 rounded-lg flex items-center space-x-4">
            <img
              src="/assets/images/whiteOwlLogo.png"
              alt="Scoolish Logo"
              className="w-16 h-16"
            />
            <span className="text-white text-lg">Scoolish</span>
            <i className="fa fa-chevron-down ml-2"></i>
          </div>
        </h1>
        <Sidebar />
      </div>
      <div className="flex-1 overflow-y-auto p-6 ml-80">
        <header className="fixed top-0 left-80 right-0 flex items-center justify-between px-10 py-6 bg-black z-10">
          {/* Search bar */}
          <div className="flex space-x-4 pl-6 items-center flex-1">
            <span className="flex items-center">
              <input
                type="text"
                placeholder="Search Projects, features and tools"
                className="px-4 py-3 rounded-l-2xl bg-secondary text-sm placeholder-gray-400 w-[28rem]"
              />
              <button className="bg-secondary text-gray-400 hover:text-gray-500 p-2.5 rounded-r-3xl">
                <i className="fa fa-microphone pr-1"></i>
              </button>
            </span>
          </div>
          {/* Profile section */}
          <div className="flex space-x-6 items-center p-3 pl-8 pr-8 rounded-lg bg-secondary">
            <div className="w-10 h-10 rounded-full bg-gray-600 flex items-center justify-center">
              <img
                src="/assets/images/avatar.png"
                alt="User Avatar"
                className="w-10 h-10 rounded-full"
              />
            </div>
            <span className="text-base">Novneet</span>
            <i className="fa fa-chevron-down ml-2"></i>
          </div>
        </header>
        <main className="flex-grow p-10 pt-28">
          {/* Top Section */}
          <section className="mb-10">
            <div className="bg-secondary p-8 rounded-lg flex flex-row">
              <div className="w-8/10 ml-4 mr-10">
                <h2 className="text-3xl font-bold mb-4 text-left">
                  Explore the all new 3D AI Drawing Section
                </h2>
                <h2 className="text-base mb-4 text-left">
                  Powered by GPT models, this module will bring out your
                  creative best
                </h2>
                <Button className="bg-[#6D3AFF] text-white mt-12 px-6 py-3 rounded mr-6">
                  Try It Now
                </Button>
                <Button className="bg-black text-white px-6 py-3">
                  More Details
                </Button>
              </div>
              <div className="w-2/10 px-12 ml-8 rounded-lg bg-gradient-to-b from-pink-300 to-purple-600">
                <img
                  src="/assets/images/explore.png"
                  alt="Explore"
                  className="w-full h-52 object-cover"
                />
              </div>
            </div>
          </section>

          {/* Features Section */}
          <section className="mb-12">
            <div className="flex justify-between w-full">
              <span className="text-2xl font-bold mt-6 mb-6">
                Start with testing Scoolish features
              </span>
              <span className="text-base font-inter">
                Show all Features
                <i className="ml-4 p-3 border border-white bg-secondary rounded-3xl fa fa-arrow-right"></i>
              </span>
            </div>
            <div className="grid grid-cols-4 gap-8">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <div key={index} className="rounded-lg bg-secondary">
                    <div className="rounded-lg">
                      <img
                        src="/assets/images/scoolishFeature.png"
                        alt="Feature"
                        className="w-full h-44 rounded-lg"
                      />
                    </div>
                    <h4 className="text-lg text-center font-bold mt-4">
                      Summarizer
                    </h4>
                    <div className="border-2 border-white rounded-lg mt-4">
                      <button className="py-3 w-full text-sm flex items-center justify-center">
                        <i className="fa fa-plus-circle pl-2 pr-2"></i>
                        Upload a New File
                      </button>
                    </div>
                  </div>
                ))}
              <div className="rounded-lg flex justify-center items-center">
                <div className="border-2 border-white rounded-lg px-8">
                  <button className="py-3 w-full text-sm flex items-center">
                    <i className="fa fa-plus-circle pl-2 pr-2"></i>
                    Upload a New File
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* Tools Section */}
          <section className="mb-12">
            <div className="flex justify-between w-full">
              <span className="text-2xl font-bold mt-6 mb-6">
                Scoolish AI Tools
              </span>
              <span className="text-base font-inter">
                Show all Tools
                <i className="ml-4 p-3 border border-white bg-secondary rounded-3xl fa fa-arrow-right"></i>
              </span>
            </div>

            <div className="grid grid-cols-4 gap-8">
              {[
                {
                  title: "AI-Powered Homework Helper",
                  description: "Your study buddy, powered by AI.",
                },
                {
                  title: "Story to Comics Converter",
                  description: "Transform stories into visual tales.",
                },
                {
                  title: "Music to Study By",
                  description: "The perfect soundtrack for focus.",
                },
                {
                  title: "Digital Debate Platform",
                  description: "Develop arguments, practice persuasion.",
                },
              ].map((tool, index) => (
                <div key={index} className="rounded-lg bg-secondary">
                  <div className="rounded-lg">
                    <img
                      src="/assets/images/scoolishFeature.png"
                      alt="Tool"
                      className="w-full h-40 rounded-lg"
                    />
                  </div>
                  <h4 className="text-lg text-center font-bold mx-4 mt-4">
                    {tool.title}
                  </h4>
                  <h2 className="text-sm text-center mt-2 mb-6">
                    {tool.description}
                  </h2>
                </div>
              ))}
            </div>
          </section>

          {/* Tutorials */}
          <section>
            <div className="flex justify-between w-full">
              <span className="text-2xl font-bold mt-6 ">
                Tutorials <br />
                <span className="text-sm font-semibold">
                  How to include Scoolish AI in your flow
                </span>
              </span>

              <span className="text-base font-inter">
                Show all Tools
                <i className="ml-4 p-3 border border-white bg-secondary rounded-3xl fa fa-arrow-right"></i>
              </span>
            </div>
            <div className="grid grid-cols-2 gap-8 mt-6">
              <div className="bg-black w-full h-[291px] rounded-xl">
                <img
                  src="/assets/images/vedio1.png"
                  alt="Vedio 1"
                  className="w-full h-80 rounded-xl"
                />
              </div>
              <div className="bg-black w-full h-[291px] rounded-xl">
                <img
                  src="/assets/images/vedio2.png"
                  alt="Image 2"
                  className="w-full h-80 rounded-xl"
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
