import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "../pages/login/LoginForm";
import SignupForm from "../pages/login/SignupForm";
import SubscribeNotification from "../pages/login/SubscribeNotification";
import AuthNotification from "../pages/login/AuthNotification";
import Home from "../pages/home/Home";
import Registration from "../pages/login/Registration";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/dashboard/Dashboard";

const AppRouter = () => {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<LoginForm/>} />
          <Route path="/signup" element={<SignupForm/>} />
          <Route path="/register" element={<Registration />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/auth" element={<ProtectedRoute> <AuthNotification /></ProtectedRoute>} />
          <Route path="/subscribe" element={<ProtectedRoute> <SubscribeNotification /></ProtectedRoute>} /> */}
          <Route path="/home" element={<ProtectedRoute> <Home /></ProtectedRoute >} /> 
        </Routes>
      </Router>
    );
  };
  
  export default AppRouter;