import React, { Suspense } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import LazyVideo from "../../components/LazyVideo";

import HomeCard from "../../components/HomeCard";
import CosmicConverter from "../../components/CosmicConverter";
import ContactUs from "../../components/ContactUs";
import Plans from "../../components/Plans";
import PremiumPlan from "../../components/PremiumPlan";
import IndustrySection from "../../components/IndustrySection";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-white">
        <section className="h-screen w-full flex justify-center items-center relative">
          <LazyVideo src="https://d3phaj0sisr2ct.cloudfront.net/site/videos/gen-3-alpha/gen-3-alpha-output-001.mp4" />
        </section>

        {/* Main Content */}
        <main className="py-4 bg-white text-center">
          {/* Summarization Cards */}
          <Suspense fallback={<div>Loading Summarization Cards...</div>}>
            <section className="py-16 mb-10">
              <h1 className="text-3xl md:text-5xl text-black">
                Welcome to Scoolish AI: Unleash Your Creativity
              </h1>
              <p className="mt-4 text-3xl md:text-5xl text-black">
                with Simple to use cutting-edge AI tools
              </p>
              <h2 className="text-xl font-bold text-black-700 mt-8 mb-8">
                OUR EDGE
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                <HomeCard
                  logo={"/assets/images/writer.png"}
                  title={"Segmentation"}
                  summary={"Segment upto 1000 pages at one go"}
                />
                <HomeCard
                  logo={"/assets/images/creator.png"}
                  title={"Topic Modelling"}
                  summary={"Model upto 1000 pages at one go"}
                />
                <HomeCard
                  logo={"/assets/images/progress.png"}
                  title={"Sentiment Analysis"}
                  summary={"Analyse upto 1000 pages at one go"}
                />
                <HomeCard
                  logo={"/assets/images/progress.png"}
                  title={"Chronology"}
                  summary={"Chronolize upto 1000 pages at one go"}
                />
                <HomeCard
                  logo={"/assets/images/progress.png"}
                  title={"Summarization"}
                  summary={"Summarize upto 1000 pages at one go"}
                />
              </div>
            </section>

            {/* AI TOOLS Cards */}
            <section className="py-20 bg-black text-white">
              <h1 className="text-6xl text-white-800 ">
                Introducing our AI TOOLS:
              </h1>
              <h1 className="text-6xl text-white">
                Over 30 integrated and engaging tools{" "}
              </h1>
              <h1 className="text-6xl text-white-800">
                to bring out your innovative best
              </h1>
              <div className="mt-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 max-w-6xl mx-auto">
                <div className="bg-white text-black px-8 py-2 rounded-xl shadow-md flex flex-col items-left h-[340px]">
                  <h2 className="text-3xl font-bold text-left mt-4">
                    Your study buddy, powered by AI
                  </h2>
                  <h2 className="text-2xl font text-left ">
                    AI-Powered Homework Helper
                  </h2>
                  <p className="text-left text-lg font-semibold mt-8">
                    This tool provides instant support for homework questions,
                    offering hints, explanations, and solutions powered by AI.
                    It encourages students to understand the process rather than
                    just find answers, fostering independent learning.
                  </p>
                  <div className="w-md flex justify-left mt-8">
                    <Button className="bg-black text-white px-2 py-2 pl-8 pr-8 hover:bg-gray-500 transition">
                      Try HH Now
                    </Button>
                  </div>
                </div>
                <div className="bg-white text-black px-8 py-2 rounded-xl shadow-md flex flex-col items-left h-[340px]">
                  <h2 className="text-3xl font-bold text-left mt-4">
                    Experiment without limits
                  </h2>
                  <h2 className="text-2xl font text-left">Virtual Science</h2>
                  <p className="text-left text-lg font-semibold mt-8">
                    This tool provides instant support for homework questions,
                    offering hints, explanations, and solutions powered by AI.
                    It encourages students to understand the process rather than
                    just find answers, fostering independent learning.
                  </p>
                  <div className="w-md flex justify-left mt-8">
                    <Button className="bg-black text-white px-2 py-2 pl-8 pr-8 hover:bg-gray-500 transition">
                      Try VS Now
                    </Button>
                  </div>
                </div>
              </div>
            </section>
            {/* COSMIC CONVERTER */}
          </Suspense>

          <Suspense fallback={<div>Loading...</div>}>
            <CosmicConverter />

            {/* Scoolish AI Scapper */}
            <section className="bg-black pt-16">
              <h1 className="text-5xl text-white font-semibold text-center">
                Scoolish AI Scraper
              </h1>
              <div className="min-h-screen bg-black text-white flex justify-center items-center px-4">
                <div className="max-w-7xl w-full flex flex-col lg:flex-row items-center">
                  {/* Left Section */}
                  <div className="w-full lg:w-1/2 space-y-6 lg:pr-12">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Find an autobot transforming"
                        className="w-full p-4 rounded-lg bg-black text-white ring-1 ring-white focus:outline-none focus:ring-2 focus:ring-white"
                      />
                    </div>
                    <div className="relative">
                      <img
                        src="/assets/images/autobotImage.jpeg"
                        alt="Autobot Transforming"
                        className="rounded-lg"
                      />
                      <Button className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 hover:bg-opacity-75 transition duration-300">
                        <a href="#" className="text-gray-500 hover:text-white">
                          <i className="fa fa-play"></i>
                        </a>
                      </Button>
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
                    <p className="text-lg text-left text-white px-16 pt-16 leading-relaxed">
                      The Scoolish AI Scraper is a powerful tool designed to
                      streamline information gathering for both students and
                      educators. By intelligently extracting data from various
                      sources, it provides organized, relevant insights to
                      support research, assignments, and lesson planning. With
                      its AI-driven algorithms, the Scoolish Scrapper simplifies
                      complex searches, saves time, and ensures that users
                      access high-quality, accurate information. Ideal for
                      enhancing study resources and staying informed, the
                      Scoolish AI Scrapper brings the world’s knowledge directly
                      to your fingertips, transforming data into actionable
                      insights for impactful learning.
                    </p>
                    <Button className="mt-8 px-6 py-3 bg-white text-black font-semibold hover:bg-gray-500 transition duration-300">
                      See All Tools
                    </Button>
                  </div>
                </div>
              </div>
            </section>
            {/* Scoolish AI Translation */}
            <section className="bg-white">
              <div className="px-64">
                <h2 className="text-5xl font-semibold mt-8 mb-2 text-center">
                  Scoolish AI Translation in{" "}
                  <span className="text-pink-600">120+</span> Languages Engaging
                  Fun Learning and Activities
                </h2>
                <div className="justify-center">
                  <img
                    src={"/assets/images/languageTranslation.jpg"}
                    alt="Scoolish Logo"
                    className="h-600 w-full"
                  />
                </div>
              </div>
              <div className="container mx-auto px-4 py-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-white p-8 rounded-lg">
                    <img
                      src={"/assets/images/aiImage.jpeg"}
                      alt="AI Image"
                      className="w-h h-full object-cover rounded-lg"
                    />
                  </div>
                  <div className="text-black pl-0 p-16 pr-32 mt-8">
                    <h2 className="text-3xl text-left font-bold mb-4">
                      Welcome to Scoolish AI
                    </h2>
                    <p className="text-lg text-justify mb-6 text-left">
                      Scoolish AI is transforming education by empowering
                      students and educators with innovative, personalized tools
                      that enhance learning outcomes. Through AI-driven
                      insights, Scoolish adapts to each student's unique pace
                      and style, offering tailored guidance and resources that
                      make learning accessible and engaging. Teachers gain
                      access to comprehensive analytics, enabling them to
                      understand student progress and customize lessons
                      effectively. Scoolish AI goes beyond traditional methods
                      by incorporating real-time feedback, interactive content,
                      and adaptive learning paths that support critical thinking
                      and creativity. By creating a dynamic learning
                      environment, Scoolish AI inspires students to reach their
                      full potential, making education a journey of discovery,
                      growth, and success.
                    </p>
                    <div className="">
                      <Button className="bg-black hover:bg-grey-500 text-white font-bold py-2 px-4">
                        Start Free Trial
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
                  <div className="text-black pl-32 p-16 pr-0">
                    <h2 className="pl-8 text-3xl text-left font-bold mb-4">
                      Who We Are?
                    </h2>
                    <p className="pl-8 text-lg text-justify text-left ">
                      The Scoolish team is a passionate group of educators,
                      technologists, and innovators committed to revolutionizing
                      the way students learn and teachers teach. With expertise
                      in artificial intelligence, education technology, and
                      user-centered design, the team at Scoolish works
                      tirelessly to create tools that are not only powerful but
                      also intuitive and engaging. They believe in the potential
                      of every student and are driven by a shared vision to make
                      learning accessible, personalized, and fun for all. By
                      blending insights from academia, industry, and classroom
                      experience, the Scoolish team continually evolves their
                      platform to meet the changing needs of educators and
                      learners alike, ensuring that education keeps pace with
                      the future. Their commitment is to empower schools and
                      students, inspiring a new generation of lifelong learners.
                    </p>
                    <Button className="bg-black hover:bg-grey-500 text-white font-bold py-2 px-4">
                      Know More
                    </Button>
                  </div>
                  <div className="bg-white p-8 rounded-lg">
                    <img
                      src={"/assets/images/vrImage.png"}
                      alt="VR Image"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </section>
            <PremiumPlan />
            <Plans />
            <ContactUs />
            {/* <IndustrySection /> */}
          </Suspense>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;
