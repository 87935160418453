import React from "react";

export default function SidebarList({items}) {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index} className="text-xs font-inter text-t-primary px-6 p-1 hover:bg-black">
          <i class={item.class}></i> {item.name}
        </li>
      ))}
      <hr class="mx-auto my-4 border-gray-300 w-[70%]" />
    </ul>
  );
}
